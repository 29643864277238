/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/blueimp-gallery@2.33.0/js/blueimp-gallery.min.js
 * - /npm/blueimp-gallery@2.33.0/js/blueimp-gallery-video.min.js
 * - /npm/blueimp-gallery@2.33.0/js/blueimp-gallery-vimeo.min.js
 * - /npm/blueimp-gallery@2.33.0/js/blueimp-gallery-youtube.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
